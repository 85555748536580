

// import './footer2.css';


// const Footer = () => {
//   const currentYear = new Date().getFullYear();

//   return (
//     <div className="footer-container">
//       <div className="footer-links">
//         <a href="/" className="footer-link">Convenios</a>
//         <a href="/comunicados" className="footer-link">Beneficios Personales</a>
//       </div>
      
//       <div className="footer-top-line"></div>
      
//       <div className="footer-content">
        
//       <img src="/image/asijemin3.png" alt="Logo de ASIJEMIN" className="footer-logo" />


//         <div className="footer-section">
//           <h4>SEDE NACIONAL:</h4>
//           <p>9 de Julio Oeste 647, Capital, San Juan | </p>
//           <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
//         </div>
       
//         {/* <div className="footer-section">
//           <h4>DEL REGIONAL CENTRO:</h4>
//           <p>Pedro de Valdivia 1964 (oeste) - San Juan | </p>
//           <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
//         </div> */}
//         <div className="footer-section">
//           <h4>DEL REGIONAL SUR - PTO. SAN JULIÁN:</h4>
//           <p>Mitre 963, Puerto San Julián, Santa Cruz | </p>
//           <a href="mailto:administracion.secsur@asijemin.org.ar">administracion.secsur@asijemin.org.ar</a> | 
//           <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
//         </div>
//         <div className="footer-section">
//           <h4>DEL REGIONAL SUR - PERITO MORENO:</h4>
//           <p>Av. Perón 1730, Perito Moreno, Santa Cruz | </p>
//           <a href="mailto:sedeperitomoreno@asijemin.org.ar">sedeperitomoreno@asijemin.org.ar</a>
//         </div>
//       </div>

//       <div className="footer-bottom">
//         <p>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong> {currentYear}</p>
//       </div>
//     </div>
//   );
// };

// export default Footer;



// <div className="footer-logo-container">
// <img src="/image/asijemin3.png" alt="Logo de ASIJEMIN" className="footer-logo" />
// </div>









import './footer2.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-links">
        <a href="/" className="footer-link">
          Convenios
        </a>
        <a href="/beneficios_personales" className="footer-link">
          Beneficios Personales
        </a>
      </div>

      <div className="footer-top-line"></div>

      <div className="footer-content">
        <div className="row">
         
         
          <div class="col logo1">
            <div className="footer-logo-container">
              <img
                src="/image/asijemin3.png"
                alt="Logo de ASIJEMIN"
                className="footer-logo"
              />
            </div>
          </div>


          
          <div class="col directioncont">
            <div className="footer-section">
              <h4>SEDE NACIONAL:</h4>
              <p>9 de Julio Oeste 647, Capital, San Juan | </p>
              <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
            </div>
            <div className="footer-section">
              <h4>DEL REGIONAL SUR - PTO. SAN JULIÁN:</h4>
              <p>Mitre 963, Puerto San Julián, Santa Cruz | </p>
              <a href="mailto:administracion.secsur@asijemin.org.ar">
                administracion.secsur@asijemin.org.ar
              </a>{" "}
              |<a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
            </div>
            <div className="footer-section">
              <h4>DEL REGIONAL SUR - PERITO MORENO:</h4>
              <p>Av. Perón 1730, Perito Moreno, Santa Cruz | </p>
              <a href="mailto:sedeperitomoreno@asijemin.org.ar">
                sedeperitomoreno@asijemin.org.ar
              </a>
            </div>
          </div>

          <div className="col">
    <div className="footer-logo-container2">
        <img
            src="/image/cta.png"
            alt="Logo cta"
            className="footer-logo-second"
        />
        <img
            src="/image/fetia.png"
            alt="Logo fetia"
            className="footer-logo-second"
        />
        <img
            src="/image/fsm.png"
            alt="Logo fsm"
            className="footer-logo-second"
        />
    </div>
</div>


        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; Todos los derechos reservados <strong>ASIJEMIN</strong>{" "}
          {currentYear}
        </p>
      </div>
    </div>
  );
};

export default Footer;
