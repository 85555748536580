import React from 'react'
import './BeneficiosPersonales.css'
import { Helmet } from 'react-helmet';
import ComunicadosLS from '../ComunicadosLS/ComunicadosLS';


const BeneficiosPersonales = () => {
  return (

<>

      <Helmet>
        <title>Beneficios Personales | Accion Social ASIJEMIN</title>
      </Helmet>

      <ComunicadosLS/>
</>

)
}

export default BeneficiosPersonales