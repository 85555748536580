


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TopFive.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

const TopFive = () => {
  const [topFiveData, setTopFiveData] = useState([]);
  const [error, setError] = useState(null); // Para manejar errores
  const navigate = useNavigate();
  
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Detecta si es mobile

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/favoritos/mas-guardados');
        console.log('Respuesta completa de la API:', response.data);
        console.log('Mensaje:', response.data.message);

        // Verificamos que 'message' exista y que sea un array
        if (response.data && Array.isArray(response.data.message)) {
          setTopFiveData(response.data.message);
        } else {
          console.error('Error: Datos no válidos');
          setError('Datos no válidos');
        }
      } catch (error) {
        console.error('Error al obtener los datos del top five:', error);
        setError('No se pudieron obtener los datos');
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (topFiveData.length === 0) {
    return <div>Cargando...</div>; // Mientras carga, muestra este mensaje
  }

  const handleNameClick = (convenioId) => {
    navigate(`/convenios/${convenioId}`);
  };

  // Componente Mobile (Galería/Desplazamiento horizontal)
  const MobileView = () => (
    <div className="mobile-slider">
      <div className="slider-wrapper">
        {topFiveData.map((item, index) => (
          <motion.div 
            key={index} 
            className="slide-item"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleNameClick(item.convenio[0]._id)}
          >
            <img 
              src={Array.isArray(item.convenio[0].imagen) ? item.convenio[0].imagen[0] : item.convenio[0].imagen}
              alt={item.convenio[0].nombre}
              className='img-mobile'
            />
  
            {/* Añadir el nombre de la provincia con el punto */}
            <p className="etiqueta-img">
              <i className="fas fa-circle me-2 small fw-bold"></i>
              {item.convenio[0].provincia}
            </p>
  
            {/* Añadir el título del convenio */}
            <h2>{item.convenio[0].nombre}</h2>
  
            {/* Añadir la corona si el convenio está destacado */}
            {item.convenio[0].destacado === true && (
              <div className="corona">
                <i className="fas fa-crown"> </i>
              </div>
            )}
            
          </motion.div>
        ))}
      </div>
    </div>
  );
  

  // Componente Desktop (el diseño original que ya tenés)
  const DesktopView = () => (
    <motion.div
      drag
      dragConstraints={{
        top: -50,
        left: -50,
        right: 50,
        bottom: 50,
      }}
    >
      <div>
        <div className="contenedortop5">
          {topFiveData[0] && (
            <motion.div
              className="cont1"
              layout
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                src={Array.isArray(topFiveData[0].convenio[0].imagen) ? topFiveData[0].convenio[0].imagen[0] : topFiveData[0].convenio[0].imagen}
                alt=""
              />
              <p className="etiqueta-img">
                <i className="fas fa-circle me-2 small fw-bold"></i>
                {topFiveData[0].convenio[0].provincia}
              </p>
              <h2 className="title-noticia" onClick={() => handleNameClick(topFiveData[0].convenio[0]._id)}>
                {topFiveData[0].convenio[0].nombre}
              </h2>
              {topFiveData[0].convenio[0].destacado === true && (
                <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                  <i className="fas fa-crown text-base mr-2 "></i>
                </div>
              )}
            </motion.div>
          )}

          <div className="cont2">
            {topFiveData[1] && (
              <motion.div
                className="avsuperior"
                layout
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  src={Array.isArray(topFiveData[1].convenio[0].imagen) ? topFiveData[1].convenio[0].imagen[0] : topFiveData[1].convenio[0].imagen}
                  alt=""
                />
                <p className="etiqueta-imgsuperior">
                  <i className="fas fa-circle me-2 small fw-bold"></i>
                  {topFiveData[1].convenio[0].provincia}
                </p>
                <h2 className="title-noticia-superior" onClick={() => handleNameClick(topFiveData[1].convenio[0]._id)}>
                  {topFiveData[1].convenio[0].nombre}
                </h2>
                {topFiveData[1].convenio[0].destacado === true && (
                  <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                    <i className="fas fa-crown text-base mr-2 "></i>
                  </div>
                )}
              </motion.div>
            )}

            {topFiveData[2] && (
              <motion.div
                className="infder"
                layout
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  src={Array.isArray(topFiveData[2].convenio[0].imagen) ? topFiveData[2].convenio[0].imagen[0] : topFiveData[2].convenio[0].imagen}
                  alt=""
                />
                <p className="etiqueta-infder">
                  <i className="fas fa-circle me-2 small fw-bold"></i>
                  {topFiveData[2].convenio[0].provincia}
                </p>
                <h2 className="title-noticia-derecha" onClick={() => handleNameClick(topFiveData[2].convenio[0]._id)}>
                  {topFiveData[2].convenio[0].nombre}
                </h2>
                {topFiveData[2].convenio[0].destacado === true && (
                  <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                    <i className="fas fa-crown text-base mr-2 "></i>
                  </div>
                )}
              </motion.div>
            )}

            {topFiveData[3] && (
              <motion.div
                className="iniz"
                layout
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  src={Array.isArray(topFiveData[3].convenio[0].imagen) ? topFiveData[3].convenio[0].imagen[0] : topFiveData[3].convenio[0].imagen}
                  alt=""
                />
                <p className="etiqueta-infiz">
                  <i className="fas fa-circle me-2 small fw-bold"></i>
                  {topFiveData[3].convenio[0].provincia}
                </p>
                <h2 className="title-noticia-izquierda" onClick={() => handleNameClick(topFiveData[3].convenio[0]._id)}>
                  {topFiveData[3].convenio[0].nombre}
                </h2>
                {topFiveData[3].convenio[0].destacado === true && (
                  <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                    <i className="fas fa-crown text-base mr-2 "></i>
                  </div>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}
    </>
  );
};

export default TopFive;
