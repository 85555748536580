
import React, { useState } from 'react';
import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
import { Card } from "@tremor/react";
import { Helmet } from "react-helmet";
import "./AlojamientoComponent.css";
import "./AlojamientoComponentTailwinds.css";
import { MdAir } from "react-icons/md";
import Carousel from '../Carrusel/Carrusel.jsx';

const AlojamientoComponent = ({ item }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);



  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === item.imagen.length - 1 ? 1 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 1 ? item.imagen.length - 1 : prevIndex - 1
    );
  };

  const wifi = item?.wifi === "si" && (
    <div className="tw-flex tw-flex-col tw-items-center tw-mb-4">
      <i className="fa-solid fa-wifi tw-mb-1"></i>
      <span className="tw-text-[10px]">WiFi</span>
    </div>
  );

  const piscina = item?.piscina === "si" && (
    <div className="tw-flex tw-items-center tw-flex-col tw-mb-4">
      <svg width="16" height="16" viewBox="0 0 24 24">
        <path d="M22 21c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.08.64-2.19.64-1.11 0-1.73-.37-2.18-.64-.37-.23-.6-.36-1.15-.36s-.78.13-1.15.36c-.46.27-1.08.64-2.19.64v-2c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36v2z" />
      </svg>
      <span className="tw-text-[10px]">Piscina</span>
    </div>
  );

  const estacionamiento = item?.estacionamiento === "si" && (
    <div className="tw-flex tw-flex-col tw-items-center tw-mb-4">
      <i className="fa-solid fa-square-parking tw-mb-1"></i>
      <span className="tw-text-[10px]">Estacionamiento</span>
    </div>
  );

  const aireacc = item?.aire === "si" && (
    <div className="tw-flex tw-items-center tw-flex-col tw-mb-4">
      <MdAir />
      <span className="tw-text-[10px]">ACC</span>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{item.nombre} | Accion Social ASIJEMIN</title>
      </Helmet>

      <div
        className={`tw-flex tw-bg-[#f3f3f3] ${
          isModalOpen ? "blur-bg" : ""
        } tw-flex-col lg:tw-flex-row`}
      >
        {/* Columna de imagen */}
        <div className="tw-w-full lg:tw-w-1/2">
          <div className="tw-relative tw-w-[90%] tw-h-auto tw-rounded-lg lg:tw-w-full tw-mx-auto tw-overflow-hidden divimg">
            <img
              src={item.imagen[0]}
              alt={item.nombre || "Imagen del convenio"}
              className="tw-w-full tw-h-auto"
            />
            {item.destacado && (
              <div className="fabv">
                <i className="fas fa-crown tw-text-base"></i>
              </div>
            )}
          </div>
        </div>

        {/* Columna de detalles */}
        <div className="tw-w-full lg:tw-w-1/2 tw-p-4">
          <div className="tw-bg-[#e0e0e0] tw-flex tw-items-center tw-rounded-lg tw-shadow-sm">
            <div className="tw-w-3/4 tw-py-4 tw-px-2">
              <h2 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-[#707173]">
                {item.nombre}
              </h2>
            </div>
            {item.tipo === "alojamiento" && (
              <div className="tw-w-1/4 tw-flex tw-items-center tw-justify-left">
                <h2 className="tw-text-[#707173]">
                  <CantidadIconos cantidad={item.estrellas} />
                </h2>
              </div>
            )}
          </div>

          <div className="tw-bg-[#eae8e8] tw-h-10 tw-my-4 tw-rounded-lg tw-shadow-sm">
            <h2 className="tw-px-4 tw-text-[#707173] tw-font-normal tw-py-[8px]">
              <i className="fa-solid fa-location-dot tw-mr-2"></i>{" "}
              {item.direccion} - {item.provincia} - {item.localidad}
            </h2>
          </div>

          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-bg-slate-50 tw-rounded-lg tw-shadow-md">
            <div className="tw-w-full lg:tw-w-3/5 tw-p-4">
              {item.descripcion?.split("\n").map((linea, i) => (
                <p key={i}>{linea}</p>
              ))}

              <div className="tw-mt-4 tw-bg-white tw-border tw-border-black-400 tw-rounded-lg">
                <Card className="tw-grid tw-grid-cols-4 tw-py-3 ">
                  {wifi}
                  {estacionamiento}
                  {aireacc}
                  {piscina}
                </Card>
              </div>
            </div>

            <div className="tw-w-full lg:tw-w-2/5 tw-px-2">
              {item.descuento !== 0 && (
                <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                  <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                    <i className="fa-solid fa-percent tw-mr-2"></i>{" "}
                    {item.descuento}%
                  </p>
                </div>
              )}

              {item.telefono?.toString().length > 4 && (
                <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                  <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                    <i className="fa-solid fa-phone tw-mr-2"></i>{" "}
                    {item.telefono}
                  </p>
                </div>
              )}

              {item.whatsaap?.toString().length > 4 && (
                <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                  <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                    <i className="fa-brands fa-whatsapp tw-mr-2"></i>{" "}
                    {item.whatsaap}
                  </p>
                </div>
              )}

              <div className="tw-h-20 tw-bg-slate-500 tw-border-t tw-border-white tw-flex tw-items-center tw-justify-center tw-rounded-lg">
                <PDFGenerator item={item} />
              </div>

              {item.imagen.length > 1 && (
                <Carousel
                  images={item.imagen.slice(1)} // Muestra imágenes a partir de la segunda
                  onOpenModal={openModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <button onClick={prevImage} className="modal-prev">
            &#10094;
          </button>
          <img
            src={item.imagen[currentIndex]}
            alt={`Imagen ${currentIndex + 1}`}
          />
          <button onClick={nextImage} className="modal-next">
            &#10095;
          </button>
          <span className="modal-close" onClick={closeModal}>
            ×
          </span>
        </div>
      )}
    </>
  );
};

export default AlojamientoComponent;
