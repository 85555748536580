

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Favoritos.css';
import { Helmet } from 'react-helmet';

const Favoritos = () => {
  const [favoritosFromDB, setFavoritosFromDB] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 6;
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favoriteItems')) || [];
    setFavoriteItems(storedFavorites);
  }, []);

  useEffect(() => {
    const fetchFavoritosFromDB = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/convenios');
        
        // Asegúrate de que la respuesta contiene un array
        if (Array.isArray(response.data)) {
          setFavoritosFromDB(response.data);
        } else if (response.data.message) {
          setFavoritosFromDB(response.data.message);  // Si la API devuelve los datos bajo `message`
        } else {
          setError('La respuesta de la API no contiene un listado de convenios.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchFavoritosFromDB();
  }, []);

  const isFavorite = (itemId) => {
    return favoriteItems.includes(itemId);
  };

  const addToFavorites = (itemId) => {
    if (isFavorite(itemId)) {
      const updatedFavorites = favoriteItems.filter((favId) => favId !== itemId);
      setFavoriteItems(updatedFavorites);
      localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteItems, itemId];
      setFavoriteItems(updatedFavorites);
      localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));
    }
  };

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  const currentElements = favoritosFromDB.filter((item) => isFavorite(item._id)).slice(indexOfFirstElement, indexOfLastElement);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(favoritosFromDB.filter((item) => isFavorite(item._id)).length / elementsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Helmet>
          <title> Mis Favoritos | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className='divider'>
        <h3 className='fn-montserrat'>Listado de Favoritos</h3>
      </div>

      <div className="row">
        {error ? (
          <div>{error}</div>
        ) : (
          currentElements.map((item) => (
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 mb-3 conetenedor-cards" key={item._id}>
              <div className="card card-anuncios mx-2 w-100 cards-principales" style={{ maxWidth: "427px" }}>
                
                <div className="contentimg">
                  <img src={item.imagen[0]} alt={item.nombre} className="card-img top" />
                  {item.destacado && (
             
             <div className="fabv">
               <i className="fas fa-crown tw-text-base"></i>
             </div>
           )}
                </div>

                <div className="card-body">
                  <h5 className="card-title">{item.nombre}</h5>
                  <p className="card-text">{item.descripcion}</p>
                  <div className="card-actions">
                    <Link to={`/convenios/${item._id}`} className="subtitle_line">Saber Más <i className="fas fa-arrow-right-long"></i></Link>
                    {isFavorite(item._id) ? (
                      <i className="fa-solid fa-heart favorito-corazon" onClick={() => addToFavorites(item._id)}></i>
                    ) : (
                      <i className="fa-regular fa-heart" onClick={() => addToFavorites(item._id)}></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Favoritos;

